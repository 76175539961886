import { createRoot } from 'react-dom/client';
import './assets/css/mortgage-theme.css'
// import { loadD3Script } from '@agn/util';
import Mortgage from './Mortgage';

const container = document.getElementById('idc-mortgage');
const containerType = container.getAttribute('mortgage-type');
const root = createRoot(container);
const mortgageCurrencySymbol = container.getAttribute('mortgage-currency-symbol') ? container.getAttribute('mortgage-currency-symbol') : '$';
const mortgageCurrencyFormat = container.getAttribute('mortgage-currency-format') ? container.getAttribute('mortgage-currency-format') : '###,###,###';
const mortgagePropertyPrice = Math.round(container.getAttribute('mortgage-property-price'));
const mortgagePropertyTax = container.getAttribute('mortgage-property-tax') ? Math.round(container.getAttribute('mortgage-property-tax')) : 0 ; // Yearly value is taken
const mortgageHomeOwnersInsurance = container.getAttribute('mortgage-home-owners-insurance') ? container.getAttribute('mortgage-home-owners-insurance') : 0 ; 
const mortgageHoa = container.getAttribute('mortgage-hoa');
const parsedHoa = typeof mortgageHoa === 'string' && (mortgageHoa.includes(',')  || mortgageHoa.includes('$')) ? parseInt(mortgageHoa.replace('$', '').replace('.00', '').replace(/,/g, '')) : mortgageHoa  // Monthly value is taken
const mortgageRate = container.getAttribute('mortgage-rate') ? container.getAttribute('mortgage-rate') : 7;
const mortgageLoanTerm = container.getAttribute('mortgage-loan-term') ? container.getAttribute('mortgage-loan-term'): 30;
const mortgageLoanTerms = container.getAttribute('mortgage-loan-terms') ? container.getAttribute('mortgage-loan-terms') : "10,15,20,25,30";
const mortgageIsRental = container.getAttribute('mortgage-ag-isrental') ? container.getAttribute('mortgage-ag-isrental') : '';
const mortgageContainerId = container.getAttribute('mortgage-container-id') ? container.getAttribute('mortgage-container-id') : '';
const mortgageContainerElement = document.getElementById(mortgageContainerId)

if (mortgageContainerElement && mortgageIsRental && mortgageIsRental === '1') {
    mortgageContainerElement.style.display = 'none';
}
configureScript(containerType).then(e=>{
    root.render(<Mortgage tab="home" type={containerType} mortgageCurrencySymbol={mortgageCurrencySymbol} mortgageCurrencyFormat={mortgageCurrencyFormat} mortgagePropertyPrice={mortgagePropertyPrice} mortgagePropertyTax={mortgagePropertyTax} 
    mortgageHomeOwnersInsurance={mortgageHomeOwnersInsurance} mortgageHoa={parsedHoa} mortgageRate={mortgageRate} mortgageLoanTerm={mortgageLoanTerm} mortgageLoanTerms={mortgageLoanTerms} mortgageIsRental={mortgageIsRental} />);    
});

function configureScript(type){     
    return new Promise((resolve,reject)=>{  
        if(!type || type=='report'){
            let d3Script = document.createElement("script");
            d3Script.src = "https://d3js.org/d3.v4.js";
            d3Script.onload = ()=>{ resolve(); }
            document.head.append(d3Script);  

            // let d3PolyfillScript = document.createElement("script");
            // d3PolyfillScript.src = "https://polyfill.io/v3/polyfill.min.js?features=default";
            // document.head.append(d3PolyfillScript);  
        }else{
            resolve(); 
        }     
    });
}

if (module.hot) {
    module.hot.accept();
}


