import React, { useState, useEffect, useRef } from 'react';
import './assets/css/mortgage.css'
import './assets/css/mortgage-theme.css'

export default function Mortgage({ mortgageCurrencySymbol, mortgageCurrencyFormat, mortgagePropertyPrice, mortgagePropertyTax, mortgageHomeOwnersInsurance, mortgageHoa, mortgageRate, mortgageLoanTerm, mortgageLoanTerms }) {

    const [donutchartData, setdonutchartData] = useState([]);
    const [isPMIChecked, setPMIChecked] = useState(true);
    const [monthlyPayment, setmonthlyPayment] = useState([]);
    const [propertyPrice, setPropertyPrice] = useState(mortgagePropertyPrice == '' || mortgagePropertyPrice == undefined ? 0 : mortgagePropertyPrice);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(20);
    const [downPayment, setDownPayment] = useState((downPaymentPercentage / 100) * propertyPrice);
    const [interestRate, setInterestRate] = useState(mortgageRate);
    const [loanTerm, setLoanTerm] = useState(mortgageLoanTerm);
    const [propertyTax, setPropertyTax] = useState(mortgagePropertyTax);
    const [homeOwnersInsurance, setHomeOwnersInsurance] = useState(mortgageHomeOwnersInsurance);
    const [hoa, setHoa] = useState(mortgageHoa);
    const donutChartRef = useRef(null);
    const [isEditMode, setIsEditMode] = useState(mortgagePropertyPrice === 0 || mortgagePropertyPrice == '' || mortgagePropertyPrice == undefined);
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        const handlePageLoad = () => {
            setPageLoaded(true);
        };
        
        if (document.readyState === 'complete') {
            handlePageLoad();
        } else {
            window.onload = handlePageLoad;
        }

        return () => {
            window.onload = null;
        };
    }, []);


    useEffect(() => {
        if (pageLoaded) {
            updateMonthlyPayment();
        }
    }, [pageLoaded]);

    useEffect(() => {
        setDownPayment(Math.round((downPaymentPercentage / 100) * propertyPrice));
    }, [propertyPrice]);


    useEffect(() => {
        setmonthlyPayment(currencyFormatter((calculateBasicEMI() + calculateMonthlyCharges()).toFixed(0)));
        setdonutchartData([
            [calculateBasicEMI(), (propertyTax) / 12, parseInt(hoa * 1), (homeOwnersInsurance) / 12, calculatePmiValue()]
        ])
        updateMonthlyPayment();
    }, [propertyPrice, propertyTax, hoa, downPaymentPercentage, downPayment, interestRate, loanTerm, homeOwnersInsurance, isPMIChecked])

    const calculateBasicEMI = () => {
        return (propertyPrice - downPayment) * ((interestRate / 1200) / (1 - Math.pow((1 + interestRate / 1200), (-12 * parseFloat(loanTerm)))));
    }

    const calculateMonthlyCharges = () => {
        return propertyTax / 12 + (hoa * 1) + homeOwnersInsurance / 12 + calculatePmiValue();
    }

    const calculatePmiPercent = () => {
        const t1 = Math.round((downPayment * 100) / propertyPrice)
        return (t1 >= 20 ? 0 : (t1 >= 15 && t1 <= 19) ? 0.002 : (t1 >= 10 && t1 <= 14) ? 0.0041 : (t1 >= 5 && t1 <= 9) ? 0.0059 : (t1 >= 0 && t1 <= 4) ? 0.0063 : 0);
    }

    const calculatePmiValue = () => {
        return (isPMIChecked ? ((propertyPrice - downPayment) * calculatePmiPercent() / 12) : 0);
    }

    const calculatePMIPerYear = () => {
        return Math.round((propertyPrice - downPayment) * calculatePmiPercent())
    }

    const handleInputField = (value, selectionStart, selectionEnd) => {
        const sanitizedValue = value.replace(/,/g, "");
        const result = sanitizedValue.replace(/[^0-9]/g, '').slice(0, 9);
        const trimmedResult = result.replace(/^0+/, '').slice(0, 9);

        let cursorPosition;
        if (selectionStart === value.length) {
            cursorPosition = selectionStart + (trimmedResult.length - result.length) + 1;
        } else if (value[selectionStart] === '0') {
            const newValue = value.slice(0, selectionStart) + value.slice(selectionStart + 1);
            cursorPosition = selectionStart + 1;
            return {
                value: newValue,
                cursorPosition: cursorPosition
            };
        } else {
            cursorPosition = selectionStart + (trimmedResult.length - result.length);
        }
        return {
            value: trimmedResult === '' || isNaN(trimmedResult) ? '0' : trimmedResult,
            cursorPosition: cursorPosition
        };
    };

    const handleEditviewCheckbox = () => {
        setIsEditMode(prevChecked => !prevChecked);
    };

    const handleReset = () => {
        setPropertyPrice(mortgagePropertyPrice)
        setDownPayment(Math.round((20 / 100) * mortgagePropertyPrice))
        setDownPaymentPercentage(20)
        setPropertyTax(mortgagePropertyTax)
        setHoa(mortgageHoa)
        setInterestRate(mortgageRate)
        setLoanTerm(mortgageLoanTerm)
        setHomeOwnersInsurance(mortgageHomeOwnersInsurance)
        setPMIChecked(true)
        const calculatePmiValue = () => {
            return 0
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const currencyFormatter = (value) => {
        let result = '';
        let decimalSeparator = '.';
        if (value != null) {
            let [integerPart, decimalPart] = value.toString().split(decimalSeparator);
            let data = extractFormattedData(integerPart, mortgageCurrencyFormat);
            for (let i = mortgageCurrencyFormat.length - 1, j = data.length - 1; i >= 0 && j >= 0; i--) {
                if (mortgageCurrencyFormat[i] === '#') {
                    result = data[j--] + result;
                } else {
                    result = mortgageCurrencyFormat[i] + result;
                }
            }
            if (decimalPart != null) {
                let roundedDecimalPart = parseFloat('.' + decimalPart).toFixed(2).substring(2);
                result += decimalSeparator + roundedDecimalPart;
            }
        }
        return result;
    }

    const extractFormattedData = (value, placeholderArray) => {
        let data = [];
        if (value != null) {
            for (let i = 0, j = 0; i < placeholderArray.length && j < value.length; i++) {
                if (placeholderArray[i] !== '#' && placeholderArray[i] === value[j]) {
                    j++;
                    continue;
                }
                if (placeholderArray[i] === '#') {
                    if (/[0-9]+/.test(value[j]))
                        data.push(value[j++]);
                }
            }
        }
        return data;
    }

    const DonutChart = ({ donutchartData, monthlyPayment }) => {
        const chartRef = useRef(null);
        const [donutChartWidth, setDonutChartWidth] = useState(0);

        useEffect(() => {
            const chartContainer = chartRef.current;
            const donutChartContainer = donutChartRef.current;
            const width = donutChartContainer.offsetWidth;
            while (chartContainer.firstChild) {
                chartContainer.removeChild(chartContainer.firstChild);
            }
            createChart(chartContainer, donutchartData, width);
            window.addEventListener('resize', handleDonutChartResize);
            return () => {
                window.removeEventListener('resize', handleDonutChartResize);
            };
        }, [donutchartData, donutChartWidth]);

        const colors = ["var(--principal-interest)", "var(--property-taxes)", "var(--hoa)", "var(--homeowners-insurance)", "var(--mortgage-insurance)"];
        const labels = ["Principal & Interest", "Property Taxes", "HOA", "Homeowners Insurance", "Private Mortgage Insurance"];
        const handleDonutChartResize = () => {
            const donutChartContainer = donutChartRef.current;
            setDonutChartWidth(donutChartContainer.offsetWidth);
        };
        const createChart = (chartRef, donutchartData, width) => {
            const colorScale = d3.scaleOrdinal().domain(labels).range(colors);
            const dataset = [{ value: donutchartData[0] }, { value: donutchartData[1] }, { value: donutchartData[2] }, { value: donutchartData[3] }, { value: donutchartData[4] }];

            const height = width < 394 ? 500 : 630, innerRadius = width < 394 ? width / 4.2 : height / 6, outerRadius = width < 394 ? width / 2.2 : height / 3.2;

            const svg = d3.select(chartRef).append('svg')
                .attr('width', width)
                .attr('height', height)
                .append('g')
                .attr('transform', 'translate(' + ((width) / 2) + ',' + (height / 2) + ')');

            const arc = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius)
                .cornerRadius(1);

            const pie = d3.pie()
                .value(function (d) {
                    return d.value;
                })
                .sort(null);

            const arcs = svg.selectAll('path')
                .data(pie(dataset))
                .enter()
                .append('g')
                .attr('class', 'arc');

            arcs
                .append("path")
                .attr("d", arc)
                .attr("fill", (d, i) => colorScale(i))
                .attr("stroke", (d, i) => (donutchartData[i] ? "white" : "none"))
                .attr("stroke-width", (d, i) => (donutchartData[i] ? 1 : 0))
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);

            arcs.append('text')
                .filter(d => {
                    const percentage = ((d.endAngle - d.startAngle) / (Math.PI * 2) * 100).toFixed(1);
                    return parseFloat(percentage) > 5 && parseFloat(percentage) < 100;
                })
                .attr('transform', d => `translate(${arc.centroid(d)})`)
                .attr('dy', '.35em')
                .attr('text-anchor', 'middle')
                .style("font-weight", "500")
                .attr("fill", "white")
                .attr("font-size", "14px")
                .text(d => {
                    const percentage = ((d.endAngle - d.startAngle) / (Math.PI * 2) * 100).toFixed(1);
                    return percentage.endsWith('.0') ? percentage.slice(0, -2) + '%' : percentage + '%';
                });

            function handleMouseOver(d, i) {
                const label = labels[i];
                const value = currencyFormatter(donutchartData[i]);
                const percentage = ((donutchartData[i] / donutchartData.reduce((sum, val) => sum + val, 0)) * 100).toFixed(1);
                const color = colors[i];
                const [x, y] = arc.centroid(d);
                const offset = 20;


                arcs.selectAll('.outer-arc').remove();

                let outerArc = d3.arc()
                    .innerRadius(width < 345 ? innerRadius + 35 : innerRadius + 75)
                    .outerRadius(outerRadius + 5)
                    .cornerRadius(0)
                    .startAngle(width < 345 ? d.startAngle + 0.05 : d.startAngle + .025)
                    .endAngle(width < 345 ? d.endAngle - 0.05 : d.endAngle - .025);

                arcs
                    .append('path')
                    .attr('class', 'outer-arc')
                    .attr('d', outerArc)
                    .attr('fill', 'none')
                    .attr('stroke', color)
                    .attr('stroke-width', 10)
                    .attr('stroke-opacity', 0.1)
                    .style("pointer-events", "none");

                const tooltipGroup = svg.append('g')
                    .attr('class', 'tooltip-group')
                    .attr('transform', width < 345 ? `translate(${offset + x - 70}, ${y - 48})` : `translate(${x - 60 + offset}, ${y - 18 - offset})`)
                    .style("pointer-events", "none")
                    .style("z-index", 3);

                const tooltipX = width < 345 ? offset + x - 70 : x - 60 + offset;
                const tooltipY = width < 345 ? y - 48 : y - 18 - offset;
                tooltipGroup.attr('transform', `translate(${tooltipX}, ${tooltipY})`);
                tooltipGroup.selectAll("*").remove();

                const labelWidth = tooltipGroup.append("text")
                    .attr("opacity", 0)
                    .text(label)
                    .node()
                    .getBBox().width;
                
                const valueWidth = tooltipGroup.append("text")
                    .attr("opacity", 0)
                    .text(`${value} (${percentage})%`)
                    .node()
                    .getBBox().width;
                
                const boxWidth = Math.max(labelWidth, valueWidth ) + 20;
                

                tooltipGroup.append("rect")
                    .attr("width", boxWidth)
                    .attr("height", 40)
                    .style("pointer-events", "none")
                    .style("position", "relative")
                    .style("opacity", 1)
                    .style("stroke", "rgba(0, 0, 0, 0.1)")
                    .style("stroke-width", "3px")
                    .style("filter", "url(#boxShadow)")
                    .style("opacity", .9)
                    .attr("fill", "#ffffff");

                tooltipGroup.append("rect")
                    .attr("width", 8)
                    .attr("height", 8)
                    .attr("fill", color)
                    .attr("x", 10)
                    .attr("y", 8);

                tooltipGroup.append("text")
                    .attr("dy", ".2em")
                    .attr("x", 22)
                    .attr("y", 14)
                    .attr("font-size", "12px")
                    .style("font-weight", "700")
                    .text(label);

                tooltipGroup.append("text")
                    .attr("dy", ".3em")
                    .attr("x", 22)
                    .attr("y", 28)
                    .attr("font-size", "12px")
                    .style("font-weight", "700")
                    .text(`$${value} (${percentage}%)`);

                svg.on("mousemove", function () {
                const [mouseX, mouseY] = d3.mouse(this);
                if (tooltipGroup) {
                    const tooltipWidth = tooltipGroup.node().getBBox().width;
                    const tooltipHeight = tooltipGroup.node().getBBox().height;
                    const offsetX = -50; 
                    const offsetY = -50; 
                    let tooltipX = mouseX + offsetX;
                    let tooltipY = mouseY + offsetY;
                    
                        if (tooltipX + tooltipWidth > svg.node().getBoundingClientRect().width) {
                            tooltipX = mouseX - offsetX - tooltipWidth;
                        }
                    
                        if (tooltipY + tooltipHeight > svg.node().getBoundingClientRect().height) {
                            tooltipY = mouseY - offsetY - tooltipHeight;
                        }
                    
                        tooltipGroup.attr("transform", `translate(${tooltipX},${tooltipY})`);
                }
            });
            }

            function handleMouseOut() {
                arcs
                    .select("path")
                    .transition()
                    .attr("stroke", "white")
                    .attr("stroke-width", (d, i) => (donutchartData[i] ? 1 : 0))
                    .attr("stroke-opacity", 1);
                svg.selectAll(".tooltip-group").remove();
                arcs.selectAll('.outer-arc').remove();
            }
        }
        return <div ref={chartRef}></div>;
    };

    const updateMonthlyPayment = () => {
        const EMI = calculateBasicEMI() + calculateMonthlyCharges();
        document.body.dispatchEvent(new CustomEvent('ag.mortgage.calculator.change', { detail: { 
            value1:EMI.toFixed(2), 
            value2:parseFloat(EMI.toFixed(0)) , 
            value3: '$' + currencyFormatter(EMI.toFixed(0)) , 
            value4:'$' + currencyFormatter(EMI.toFixed(2)) 
         }}));
    };


    return (
        <>
            <div className='mortgage-data'>
                <div className='mortgage-chart' ref={donutChartRef}>
                    <div className='donutchart' >
                        {donutchartData.length > 0 && monthlyPayment && (
                            <DonutChart donutchartData={donutchartData[0]} monthlyPayment={monthlyPayment} />
                        )}
                    </div>
                    {/* <div className='mortgage-footer' >
                        <ul className='legend notranslate' >
                            {calculateBasicEMI() == 0 ? <></> : <li><i style={{ background: 'var(--principal-interest)' }} ></i><i>Principal & Interest</i></li>}
                            {hoa == 0 ? <></> : <li><i style={{ background: 'var(--hoa)' }} ></i><i>HOA</i></li>}
                            {calculatePmiValue() == 0 ? <></> : <li><i style={{ background: 'var(--mortgage-insurance)' }} ></i><i>Mortgage Insurance (PMI)</i></li>}
                            {homeOwnersInsurance == 0 ? <></> : <li><i style={{ background: 'var(--homeowners-insurance)' }} ></i><i>HomeOwners Insurance</i></li>}
                            {propertyTax == 0 ? <></> : <li><i style={{ background: 'var(--property-taxes)' }} ></i><i>Property Taxes</i></li>}
                        </ul>
                        <div className='monthlypayment-container' >
                            <span>Monthly Mortgage Cost: {currencyFormatter(Math.round(calculateBasicEMI()))}</span>
                            <span>Monthly Charges: {currencyFormatter(Math.round(calculateMonthlyCharges()))}</span>
                            <span>Total monthly payment: {currencyFormatter((calculateBasicEMI() + calculateMonthlyCharges()).toFixed(0))}</span>
                        </div>
                    </div> */}
                </div>
                <div className='Calc-Form'>
                    {mortgagePropertyPrice == 0 || mortgagePropertyPrice == undefined || mortgagePropertyPrice == '' || mortgagePropertyPrice == null ? (
                        <input type="checkbox" name="" id="edit-view" checked={isEditMode} onChange={handleEditviewCheckbox} />
                    ) : (
                        <input type="checkbox" name="" id="edit-view" />)}
                    <div className='form-btn'>
                        <label htmlFor="reset" className='reset-btn' onClick={handleReset}>Reset</label>
                        <label htmlFor="edit-view" className='edit-btn' ></label>
                    </div>
                    <div className='form-view'>
                        <ul>
                            <li className='home-price dollar-bold'>
                                <label><b>Property Price</b></label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><b>{currencyFormatter(propertyPrice)}</b></span>
                            </li>
                            <li className='down-payment'>
                                <label>Down Payment ({downPaymentPercentage}%)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>{currencyFormatter(downPayment)}</span>
                            </li>
                            <li className='loan-amount dollar-bold'>
                                <label><b>Loan Amount</b></label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><b>{currencyFormatter(propertyPrice - downPayment)}</b></span>
                            </li>
                            <li className='monthly-payment dollar-bold'>
                                <label><b>Monthly Payment</b></label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><b>{currencyFormatter((calculateBasicEMI() + calculateMonthlyCharges()).toFixed(2))}</b></span>
                            </li>
                            <li className='loan'>
                                <span>({loanTerm}-year, fixed-rate loan at {interestRate}%)</span>
                            </li>
                            <li className='insurance principal-interest'>
                                <label>Principal & Interest (/m)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>{(currencyFormatter(calculateBasicEMI().toFixed(2)))}</span>
                            </li>
                            <li className='insurance property-tax'>
                                <label>Property Taxes (/m)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>{currencyFormatter((propertyTax / 12).toFixed(2))}</span>
                            </li>
                            <li className='insurance homeowners-insurance'>
                                <label>Homeowners Insurance (/m)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>{currencyFormatter((homeOwnersInsurance / 12).toFixed(2))}</span>
                            </li>
                            <li className='insurance HOA-fees'>
                                <label>HOA Dues (/m)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>{currencyFormatter((hoa * 1).toFixed(2))}</span>
                            </li>
                            <li className='insurance mortgage-insurance'>
                                <label>Private Mortgage Insurance (/m)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>{currencyFormatter(calculatePmiValue().toFixed(2))}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='htmlForm'>
                        <ul>
                            <li className='textfield property-price'>
                                <label className='textfield-label' htmlFor='PropertyPrice'>Property Price</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><input className='form-input' type='text' name='Property-Pice' value={currencyFormatter(propertyPrice)} onChange={(e) => {
                                    const updatedPropertyPriceValue = handleInputField(e.target.value, e.target.selectionStart, e.target.selectionEnd);
                                    setPropertyPrice(updatedPropertyPriceValue.value);
                                    setDownPayment(Math.round((downPaymentPercentage / 100) * propertyPrice));
                                    setTimeout(() => {
                                        e.target.setSelectionRange(updatedPropertyPriceValue.cursorPosition, updatedPropertyPriceValue.cursorPosition);
                                    }, 0);
                                }} onKeyDown={handleKeyPress} /></span>
                            </li>
                            <li className='textfield down-payment'>
                                <label className='textfield-label' htmlFor='DownPayment'>Down Payment</label>
                                <span className='payment-percentage prefix-dollar' data-currency={mortgageCurrencySymbol}>
                                    <input className='form-input' type='text' name='Down-Payment' value={currencyFormatter(downPayment)} onChange={(e) => {
                                        const updatedDownPaymentValue = handleInputField(e.target.value, e.target.selectionStart, e.target.selectionEnd);
                                        const enteredValue = parseInt(updatedDownPaymentValue.value.replace(/[^0-9]/g, ''));
                                        const newDownPayment = Math.round(Math.min(enteredValue, propertyPrice));
                                        setDownPayment(newDownPayment);

                                        const newDownPaymentPercentage = Math.round((newDownPayment / propertyPrice) * 100);
                                        setDownPaymentPercentage(propertyPrice === 0 ? 100 : newDownPaymentPercentage);
                                        setTimeout(() => {
                                            e.target.setSelectionRange(updatedDownPaymentValue.cursorPosition, updatedDownPaymentValue.cursorPosition);
                                        }, 0);
                                    }} onKeyDown={handleKeyPress} />
                                    <input className='percentage form-input' type='text' name='Down-Payment' value={downPaymentPercentage} onChange={(e) => {
                                        const enteredPercentage = Math.min(e.target.value.replace(/[^0-9]/g, ''), 100); setDownPaymentPercentage(Math.round(enteredPercentage));
                                        const newDownPayment = (enteredPercentage / 100) * propertyPrice; setDownPayment(Math.round(newDownPayment));
                                    }} onKeyDown={handleKeyPress} />
                                </span>
                            </li>
                            <li className='textfield interest-rate'>
                                <label className='textfield-label' htmlFor='InterestRate'>Interest Rate (%)</label>
                                <input className='form-input' type='text' name='Interest-Rate' value={interestRate} onChange={(e) => { setInterestRate(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./, '$1').slice(0, 9)) }} onKeyDown={handleKeyPress} />
                            </li>
                            <li className='textfield loan-term'>
                                <label className='textfield-label' htmlFor='LoanTerm'>Loan Term</label>
                                <br />
                                <select className='form-input' name='Loan-Term' value={loanTerm} onChange={(e) => setLoanTerm(e.target.value)}>
                                    {mortgageLoanTerms.split(',').map(term => parseInt(term, 10)).map((term) => (
                                        <option key={term} value={term}>
                                            {term}
                                        </option>
                                    ))}
                                </select>
                            </li>
                            <li className='insurance property-tax textfield'>
                                <label className='textfield-label' htmlFor='PropertyTaxes'>Property Taxes (/y)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}>
                                    <input className='form-input' type='text' name='Property-Tax' value={currencyFormatter(propertyTax)} onChange={(e) => {
                                        const updatedPropertyTaxValue = handleInputField(e.target.value, e.target.selectionStart, e.target.selectionEnd);
                                        setPropertyTax(updatedPropertyTaxValue.value);
                                        setTimeout(() => {
                                            e.target.setSelectionRange(updatedPropertyTaxValue.cursorPosition, updatedPropertyTaxValue.cursorPosition);
                                        }, 0);
                                    }} onKeyDown={handleKeyPress} /></span>
                            </li>
                            <li className='insurance homeowners-insurance textfield'>
                                <label className='textfield-label' htmlFor='HomeOwnersInsurance'>Homeowners Insurance (/y)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><input className='form-input' type='text' name='Association-Due' value={currencyFormatter(homeOwnersInsurance)} onChange={(e) => {
                                    const updatedHomeOwnersInsuranceValue = handleInputField(e.target.value, e.target.selectionStart, e.target.selectionEnd);
                                    setHomeOwnersInsurance(updatedHomeOwnersInsuranceValue.value);
                                    setTimeout(() => {
                                        e.target.setSelectionRange(updatedHomeOwnersInsuranceValue.cursorPosition, updatedHomeOwnersInsuranceValue.cursorPosition);
                                    }, 0);
                                }} onKeyDown={handleKeyPress} /></span>
                            </li>
                            <li className='insurance HOA-fees textfield'>
                                <label className='textfield-label' htmlFor='AssociationDues' >HOA Dues (/m)</label>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><input className='form-input' type='text' name='Association-Due' value={currencyFormatter(hoa)} onChange={(e) => {
                                    const updatedHoaValue = handleInputField(e.target.value, e.target.selectionStart, e.target.selectionEnd);
                                    setHoa(updatedHoaValue.value);
                                    setTimeout(() => {
                                        e.target.setSelectionRange(updatedHoaValue.cursorPosition, updatedHoaValue.cursorPosition);
                                    }, 0);
                                }} onKeyDown={handleKeyPress} /></span>
                            </li>
                            <li className='mortgage-insurance'>
                                <span className='PMI'>
                                    <input className='pmi-checkbox' type='checkbox' checked={isPMIChecked} onChange={() => setPMIChecked(!isPMIChecked)} onKeyDown={handleKeyPress} />
                                    <label className='textfield-label' htmlFor='PrivateMortgageInsurance'>
                                        Private Mortgage Insurance (PMI)
                                    </label>
                                </span>
                                <span className='prefix-dollar' data-currency={mortgageCurrencySymbol}><label className='textfield-label pmi-per-year' htmlFor='PMIPerYear'>{currencyFormatter(calculatePMIPerYear())}</label></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}